<template>
  <!--begin::Login Sign up form-->
  <div class="login-signup">
    <div class="mb-10">
      <h3>注册</h3>
      <p class="opacity-60" style="width: 350px;">请输入你的信息进行注册</p>
    </div>
    <form class="form text-center" id="kt_login_signup_form">
      <div role="alert" v-if="errors && errors.length > 0" v-bind:class="{ show: errors.length }" class="alert fade alert-danger" style="width: 350px;">
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error }}
        </div>
      </div>
      <div role="alert" v-if="msgs && msgs.length > 0" v-bind:class="{ show: msgs.length }" class="alert fade alert-success" style="width: 350px;">
        <div class="alert-text" v-for="(msg, i) in msgs" :key="i">
          {{ msg }}
        </div>
      </div>
      <div class="form-group">
        <input class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 border-0 py-4 px-8" v-model="user.tenantLogo" v-on:change="getGroupDbList"
          type="text" placeholder="公司邀请码"/>
      </div>
      <div class="form-group" v-if="groupDbList && groupDbList.length > 0">
        <select class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 border-0 py-4 px-8" v-model="user.groupDbId" placeholder="企业平台">
          <option v-for="item in groupDbList" :key="item.recordId" :value="item.recordId">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <input class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 border-0 py-4 px-8" v-model="user.userName" type="text" placeholder="真实姓名"/>
      </div>
      <div class="form-group">
        <div class="input-group mb-5">
          <input class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 border-0 py-4 px-8" v-model="user.phone" type="text" placeholder="手机号码"
                 name="username" autocomplete="off"/>
          <div class="input-group-append">
            <button type="button" class="btn btn-outline-secondary h-auto text-white placeholder-white opacity-70 bg-dark-o-70 border-0 py-4 px-8" v-on:click="validatePhone">发送</button>
          </div>
        </div>
      </div>
      <div class="form-group">
        <input class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 border-0 py-4 px-8 mb-5" v-model="user.phoneCode" type="text" placeholder="短信验证码"
               name="valiCode" autocomplete="off"/>
      </div>
      <div class="form-group">
        <input class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 border-0 py-4 px-8" v-model="user.password" autocomplete="new-password" type="password"
               placeholder="密码"/>
      </div>
      <div class="form-group">
        <input class="form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 border-0 py-4 px-8" v-model="user.repassword" autocomplete="new-password" type="password"
               placeholder="确认密码"/>
      </div>
      <div class="form-group text-left px-8">
        <div class="checkbox-inline">
          <label class="checkbox checkbox-outline checkbox-white text-white m-0">
            <input type="checkbox" name="agree" v-model="user.agreeFlag" />
            <span></span>我已阅读并同意
            <a href="#" class="text-white font-weight-bold ml-1 text-primary">《科易博智造云服务协议》</a>
          </label>
        </div>
        <div class="form-text text-muted text-center"></div>
      </div>
      <div class="form-group">
        <button type="button" id="kt_login_signup_submit" class="btn btn-pill btn-outline-white font-weight-bold opacity-90 px-15 py-3 m-2" v-on:click="regist">注册</button>
        <router-link to="/auth/login">
          <button type="button" id="kt_login_forgot_cancel" class="btn btn-pill btn-outline-white font-weight-bold opacity-70 px-15 py-3 m-2">取消</button>
        </router-link>
      </div>
    </form>
  </div>
  <!--end::Login Sign up form-->
</template>
<script>
export default {
  data() {
    return {
      user: {},
      groupDbList: [],
      errors: [],
      msgs: [],
    };
  },
  methods: {
    validatePhone: function() {
      this.errors = [];
      this.msgs = [];
      if (this.user) {
        if (this.user.phone && this.user.phone.length === 11) {
          // 请求后台获取验证码
          this.$axios.fetchPost("/hr/user/validateRegister", this.user).then(data => {
              if (data.data && data.data.res === "noDb") {
                this.errors.push("请填写正确的公司邀请码");
              } else if (data.data && data.data.res === "exist") {
                this.errors.push("该手机号码已经注册");
              } else if (data.data && data.data.res === "success"){
                this.user.code = data.data.code;
                this.msgs.push("短信发送成功");
                return;
              }else {
                this.errors.push("请刷新重试");
              }
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          this.errors.push("手机号码错误");
        }
      } else {
        this.errors.push("用户信息错误");
      }
    },
    regist: function() {
      this.errors = [];
      this.msgs = [];
      if (this.user) {
        if (!this.user.tenantLogo) {
          this.errors.push("请填写公司邀请码");
          return;
        }
        if (!this.user.groupDbId) {
          this.errors.push("请选择企业平台");
          return;
        }
        if (!this.user.phone) {
          this.errors.push("请输入手机号码");
          return;
        }
        if (!this.user.phoneCode) {
          this.errors.push("请输入手机验证码");
          return;
        }
        if (!this.user.password) {
          this.errors.push("请填写密码");
          return;
        }
        if (!this.user.repassword) {
          this.errors.push("请确认密码");
          return;
        }
        if (this.user.password !== this.user.repassword) {
          this.errors.push("密码不一致");
          return;
        }
        if (!this.user.agreeFlag) {
          this.errors.push("请勾选同意协议");
          return;
        }
        this.$axios.fetchPost("/hr/user/register", this.user).then(data => {
            if (data.data === "noDb") {
              this.errors.push("企业平台错误");
            } else if (data.data === "fail") {
              this.errors.push("短信验证码错误");
            } else if (data.data === "exist") {
              this.errors.push("手机号码已经存在");
            } else if (data.data === "success") {
              this.msgs.push("注册成功,即将跳转登录界面！");
              setTimeout(this.toLogin,1500);
            } else {
              this.errors.push("请刷新重试");
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.errors.push("请刷新重试");
      }
    },
    toLogin: function (){
      this.$router.push("/auth/login");
    },
    getGroupDbList: function() {
      this.errors = [];
      this.msgs = [];
      if (this.user && this.user.tenantLogo){
        const _this = this;
        this.$axios.fetchPost("/hr/user/getIcloudTenantDb", this.user).then(data => {
          if (data && data.data) {
            _this.groupDbList = data.data;
            if (_this.groupDbList && _this.groupDbList.length > 0){
              _this.user.groupDbId = _this.groupDbList[0].recordId;
            }else {
              this.errors.push("没有该邀请码对应的公司");
              _this.user.tenantLogo = "";
            }
          }
        }).catch(err => {
          console.log(err);
        });
      }
    }
  }
};
</script>
